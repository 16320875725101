export default {
  inject: ['$error'],
  data: () => ({
    fetching: true,
  }),
  async beforeMount () {
    await this.$fetch()
  },
  methods: {
    async $fetch () {
      this.fetching = true

      try {
        await this.fetch()

        if (this.fetched) {
          this.$nextTick(this.fetched)
        }
      } catch (e) {
        if (this.$error) {
          await this.$error(e)
        }

        throw e
      } finally {
        this.fetching = false
      }
    },
  },
}
