import Notification from '@/components/Page/Liff/MemberCard/Notification'

const STORAGE_KEY = 'MEMBER_CARD_NOTIFICATION_HIDDEN'

export default {
  components: { Notification },
  computed: {
    shouldShowNotification () {
      return !window.localStorage.getItem(STORAGE_KEY) && !this.card.is_bind_card
    },
  },
  methods: {
    fetched () {
      if (!this.shouldShowNotification) {
        return
      }

      this.$nextTick(() => this.$refs.notification.show())
    },
    handleNotificationClosed () {
      window.localStorage.setItem(STORAGE_KEY, 1)
    },
  },
}
