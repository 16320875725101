<template>
  <div class="s-notification" v-show="shown">
    <Info class="s-text-secondary s-mr-1" />
    您的數位會員卡尚未完成歸戶，請至
    <a class="s-link s-link-secondary" href="https://lihi1.com/ZDdSf/LINE" target="_blank">POYA Pay</a>
    歸戶以查詢完整點數資訊。
    <button class="s-close" type="button" @click="close">✕</button>
  </div>
</template>

<script>
  import Info from '@/components/Page/Liff/MemberCard/Icon/Info'

  export default {
    components: { Info },
    data: () => ({
      shown: false,
    }),
    methods: {
      show () {
        this.shown = true
      },
      close () {
        this.shown = false
        this.$emit('close')
      },
    }
  }
</script>

<style scoped>
  .s-notification {
    position: relative;
    padding: 0.5rem;
    padding-right: 1.75rem;
    background-color: var(--s-secondary-light);
  }

  .s-notification,
  .s-notification:after {
    border-radius: 0.5rem;
  }

  .s-notification:after {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    border-radius: 0.5rem;
    opacity: 0.5;
    border: 1px solid var(--s-secondary);
    pointer-events: none;
  }

  .s-close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
</style>
